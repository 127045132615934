[aria-expanded="false"] > .expanded,
[aria-expanded="true"] > .collapsed {
  display: none;
}


#jwt pre {
  margin-bottom: 0;
  padding-top: 0.125rem;
}
