.accordion-body li {
  margin-bottom: 1rem;
}

.nested-list li {
  margin-bottom: inherit;
}

#challenge pre {
  margin-bottom: 0;
  padding-top: 0.125rem;
}

#challenge textarea {
  -webkit-user-select: all; /* Safari */
  -ms-user-select: all; /* IE 10 and IE 11 */
  user-select: all; /* Standard syntax */
}
